import React, {useState} from "react"
import Layout from "../components/layout";
import {
    Card, Button, CardImg, CardTitle, CardText, CardGroup,
    CardSubtitle, CardBody, Col, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

const Blog = () => {


    const Articles = [{
        title: "Wygrany - wygrany",
        id: 1,
        desc: "Spotkanie. Przedsiębiorcy, handlowcy, menedżerowie, wolni strzelcy. Wszyscy mają cel. Zakomunikować, jak wyśmienita jest ich oferta....",
        content: "Spotkanie. Przedsiębiorcy, handlowcy, menedżerowie, wolni strzelcy. Wszyscy mają cel. Zakomunikować, jak wyśmienita jest ich oferta. Każdy chce coś zaoferować. Niewielu chce słuchać. Znasz to?\n" +
            "\n" +
            "Bardzo możliwe, że miałaś/miałeś okazję już kiedyś uczestniczyć w różnych spotkaniach integrujących ciekawe środowiska biznesowe. Oczywiście o ile w większości przypadków powyższy opis może być trafny, o tyle z pewnością zdarzają się wyjątki. Osoba, która słucha, nie musi niczego sprzedawać - jak to niektórzy nazywają \"wciskać\"- innej osobie. Osoba, która wierzy w jedno z najważniejszych prawideł społecznych, wie, że wysłuchując, można wiele więcej skorzystać. Łatwiej wtedy znaleźć wspólną płaszczynę lub po prostu nie marnować czasu i iść dalej swoją drogą - jeżeli nie ma szans na kontrakt typu win-win.\n" +
            "\n" +
            "Wybierając postawę biznesową, wiele osób chce być postrzeganym jako ktoś konkretny. To oczywiście słuszny cel. Jednak odpowiednia mieszanka asertywności, pewności siebie i zarazem otwartości jest, w moim odczuciu, bardziej wskazana.\n" +
            "\n" +
            "A Ty? Jaki wizerunkiem chcesz się móc pochwalić? Nachalny czy rozsądny? Skupiony tylko na własnych korzyściach czy dobry partner w biznesie? Jeszcze raz - bądź tu i teraz, słuchaj i buduj relacje, z których obydwie strony wychodzą wygrane.",
        src: "/images/blog1.webp",
    },
        {
            title: "Paradoks",
            id: 2,
            desc: "Rower. Domyślam się, że utrzymujesz równowagę. Może nawet jeździsz nałogowo, hobbystycznie. Nieistotne. Zamiast próbować tego co poniżej - użyj wyobraźni...",
            content: "Rower. Domyślam się, że utrzymujesz równowagę. Może nawet jeździsz nałogowo, hobbystycznie. Nieistotne. Zamiast próbować tego co poniżej - użyj wyobraźni...\n" +
                "\n" +
                "Jedziesz. W miarę rytmicznie, nie za szybko, jednak wyprzedzasz idących ludzi. Droga może nie jakaś ruchliwa, ale co jakiś czas wymijają Cię samochody. Odwracasz teraz głowę, ale zamiast po chwili ponownie skupić wzrok na drodze przed sobą, uparcie patrzysz w tył. Nie - nie spoglądaj na to, co przed Tobą. Jedź. Nieważne, że to musi się źle skończyć. Najważniejsze byś widział co minąłeś. Czy tak?\n" +
                "\n" +
                "Odnajdujesz analogię? W życiu też spotykamy na naszej drodze ludzi, którzy chcieliby jechać do przodu, ale cały czas potykają się, przewracają i kompletnie niczego ich to nie uczy. Patrzą nieustannie za siebie, koncentrując się na tym, co było. Jak sądzisz, w którą stronę powinni kierować swój wzrok? Daleko przed siebie? \n" +
                "\n" +
                "Jedno z ważniejszych przesłań, jakie wyciągnąłem z wielu motywacyjnych szkoleń, brzmi: Bądź tu i teraz. Rozmawiasz z kimś? Rozmawiaj, a właściwie słuchaj, co ma do powiedzenia. Słuchaj. Masz przecież dwoje uszu, a tylko jedne usta. To powinno dawać do myślenia. Jesz obiad z rodziną? Jedz, to nic, że telefon dzwoni. Nadużywaj słowa dziękuję - musisz mieć w końcu jakieś wady. Zastanawiałeś się kiedyś, za ile spraw możesz być faktycznie wdzięczny? Jest jeszcze coś, ale o tym wkrótce. Tymczasem...bądź tu i teraz. ",
            src: "/images/blo2.png",
        },
        {
            title: "Największy chwast!",
            id: 3,
            desc: "Chwast to godny przeciwnik. Wyrywasz go, a on uparcie wraca. Walka ta przypomina batalię z pewnym nawykiem.",
            content: "Chwast to godny przeciwnik. Wyrywasz go, a on uparcie wraca. Walka ta przypomina batalię z pewnym nawykiem.\n" +
                "\n" +
                "Narzekanie - choroba ogromnej liczby osób w polskim kraju. Narzekanie na rząd, na konkurencję, zmiany, podatki, koszty itp itd. Problem z narzekaniem polega na tym, że nakręcanie się w narzekaniu jest jednym z najczęstszych powodów niedostrzegania szans i okazji. Zwyczajnie ćwicząc się w narzekaniu i wynajdowaniu coraz to nowych rzeczy, na które można by tu ponarzekać, skupiamy na tym właśnie całą swoją uwagę.\n" +
                "\n" +
                "Stare jak świat ćwiczenie. Rozejrzyj się wokół i znajdź jak najwięcej przedmiotów w kolorze czerwonym. Daj sobie 17 sekund na tę czynność. Zrób to teraz, po czym wróć do lektury posta. Już? No to teraz zanim zamkniesz oczy, przeczytaj dalszą instrukcję: za chwilę, po zamknięciu oczu, przypomnij sobie jak najwięcej drobiazgów w otaczającej Cię przestrzeni w kolorze... beżowym lub szarym ( jeśli nie masz pojęcia, jaki to, u licha, beżowy:) ). Do dzieła. \n" +
                "\n" +
                "No i jak? Udało się? Ciekawy jest fakt, że zdarzało się, że nawet osoba, która była w swoim mieszkaniu, nieraz miała kłopot, by wymienić wszystkie. Ot, prosta zasada, na czym się skupiasz, to rośnie w Twojej głowie i zabiera miejsce czemuś, co mogłoby Cię zbliżyć do celu. A zatem...\n" +
                "\n" +
                "Zamiast narzekać, ćwicz się i praktykuj wyszukiwanie wszystkiego, za co możesz być szczerze wdzięczny. No dalej. Nikt nie widzi. Zacznij od teraz: wymień 17 rzeczy, spraw, ludzi, za które możesz być wdzięczny. Daj sobie np. 7 minut.\n" +
                "\n" +
                "No i jak? Udało się? Większość osób pisze dość sprawnie.. gdzieś do 5- 6 pozycji. Do 17 dotrą tylko Ci, którzy faktycznie chcą się rozwijać, a nie tylko mówią o tym, że warto by było. Niestety, jest spora szansa, że czytasz właśnie dalej, a nawet się nie zabrałaś/ zabrałeś do tego ćwiczenia. Zrób to teraz.\n" +
                "\n" +
                "Tymczasem... wio do ogródka. Wyrwać chwasta! Z korzeniami!\n" +
                "\n" +
                "A w to miejsce konsekwentie sadzić... wdzięczność.",

            src: "/images/blog2.jpg",
        },
        {
            title: "Grasz w tę grę?",
            id: 4,
            content: "Wyobraź sobie jakieś bezpieczne miejsce. Może Twoje mieszkanie. Przed chwilą odebrałeś telefon. Twój znajomy, z którym wspólnie opracowaliście jakąś niesamowitą rzecz, otrzymał propozycję pokazania światu tego, nad czym pracowaliście. Oczywiście dzwonił, by Tobie oddać ten zaszczyt. Wiesz już, że za chwilę masz wyjść. To niecodzienna wyprawa. Tym razem po raz pierwszy w życiu masz zrobić coś, czego jeszcze nigdy nie robiłeś. Masz wystąpić dla 100 osób na scenie i opowiedzieć im w 30 minut o Waszych osiągnięciach. To dla Ciebie duże wyzwanie. Nigdy w życiu nie występowałeś publicznie, a tym razem ma to być cała setka osób, a w dodatku musisz im zająć czas przez całe 30 min. Sprawa jest ważna i być może to jedyna taka okazja w życiu… \n" +
                "\n" +
                " \n" +
                "\n" +
                " Jak byś się czuła/czuł? Jakie emocje towarzyszyłyby Ci tuż przed wejściem na scenę?\n" +
                "\n" +
                "Bardzo możliwe, że nie byłoby żadnego problemu. Szczególnie jeżeli pochodzisz z wyżu demograficznego lat 80-tych i 90-tych, czyli jesteś członkiem pokolenia Y. Młodzi często swobodnie podchodzą do takich wyzwań. Powyższa scenka obrazuje jednak  wyjście ze strefy komfortu. Większości ludzi nie raz przyjdzie się spotykać w życiu podobną sytuacją. Robiąc coś po raz pierwszy, rozważając nawet tylko spróbowanie czegoś nowego, często będziemy przeżywali emocje podobne do tych, jakbyśmy zaraz mieli skoczyć w jakąś przepaść.\n" +
                "\n" +
                " \n" +
                "\n" +
                "Nikt z nas nie rodzi się sportowcem, lekarzem, handlowcem, menedżerem czy chemikiem. Wszyscy rodzimy się dziewczynkami lub chłopcami. To w  trakcie życia uczymy się nowych umiejętności. Co by było, gdybyś ucząc się chodzić, jako małe dziecko,  po pierwszym upadku doszedł do wniosku, że kolejna próba musi się skończyć podobnie - upadkiem i bólem? Zapewne do dziś wiódłbyś życie na czworakach.  Ktoś powiedział, że gdyby dąb miał rozum człowieka, urósłby tylko do 180cm wysokości. Najczęściej naszym własnym wrogiem staje się nasza głowa. Logicznie jesteśmy w stanie wytłumaczyć sobie, dlaczego brak działania jest przyjemniejszy, a rozwój musi kojarzyć się tylko z bólem. Dyskusja z sobą samym to gra. Grałeś w nią nieraz. Częściej wygrywasz czy przegrywasz?\n" +
                "\n" +
                " \n" +
                "\n" +
                "Czy istnieje sposób na zwycięstwo? Są przecież ludzie na świecie, którzy kiedyś nie byli dobrymi mówcami – dziś prowadzą porywające wystąpienia. Są ludzie, którzy nie potrafili nauczyć się rodzimego języka, dziś są tłumaczami przysięgłymi , czasami i w kilku językach.  Odpowiedź znajdziesz w kawale.\n" +
                "\n" +
                " \n" +
                "\n" +
                "Młody człowiek przyjechał do wielkiego miasta na koncert muzyki poważnej.  Nie był pewien, w którą stronę ma się udać po wyjściu z dworca, więc  podszedł do starszego człowieka i zapytał:\n" +
                "\n" +
                "„Przepraszam, jak się dostać do filharmonii?” Na co usłyszał odpowiedź: „ĆWICZYĆ, ĆWICZYĆ I JESZCZE RAZ ĆWICZYĆ”.",
            desc: "Wyobraź sobie jakieś bezpieczne miejsce. Może Twoje mieszkanie. Przed chwilą odebrałeś telefon",
            src: "/images/blog4.jpg"
        },
        {
            id: 5,
            title: "Ostrzyć narzędzia czas!",
            content: "Wyobraź sobie, że idziesz przez las. W pewnym momencie widzisz człowieka, który usiłuje ściąć pokaźne drzewo. Podchodzisz bliżej i widzisz, że idzie mu to bardzo opornie. Okazuje się, że ma tępą piłę. Co byś mu doradził? Prawdopodobnie zaproponowałbyś mu zaostrzenie piły. A co byś o nim pomyślał, gdyby na Twoją propozycję odpowiedział, że nie ma na to czasu, bo musi ściąć drzewo?\n" +
                "\n" +
                "Spotykam setki osób na swojej drodze. Zadziwiający jest fakt, jak wielu z nich usiłuje tępymi narzędziami osiągać spektakularne wyniki. Weźmy dla przykładu kogoś, kto przez minione 10 lat cały czas usiłuje zdobyć przewagę rynkową w ten sam sposób. Lub kogoś, kto w ten sam sposób zarabia i wydaje pieniądze, oczekując lepszych efektów w kolejnych latach. Lub kogoś, kto nie analizując swoich własnych wartości, próbuje stworzyć związek z kolejnym i kolejnym partnerem życiowym.\n" +
                "\n" +
                "Czy masz świadomość, jak niewielu ludzi inwestuje w najważniejsze narzędzie, które innym realizuje marzenia. Mowa oczywiście o łupince. Albo, jak powie ktoś inny, o tym, co mamy miedzy uszami. Jeżeli ktoś z zamkniętym umysłem próbuje utrzymać się dziś na ryknu, to sytuacja taka jest podobna do skoku ze spadochronem, ale bez jego otwierania. Sprawdzimy, jak jest u Ciebie?\n" +
                "\n" +
                "Czy wypisałaś / wypisałeś wszystkie wartości istotne w Twoim życiu?\n" +
                "\n" +
                "Czy wiesz, jakie role w życiu pełnisz?\n" +
                "\n" +
                "Czy masz spisane cele w każdej istotnej dziedzinie Twojego życia?\n" +
                "\n" +
                "Czy masz sprecyzowany plan, jak te cele zrealizować?\n" +
                "\n" +
                "Czy wiesz, jakie kroki możesz zrobić każdego dnia, by zbliżać się do celu?\n" +
                "\n" +
                "Czy wiesz, co hamuje rozwój w Twoim życiu?\n" +
                "\n" +
                "Czy wiesz dokładnie, czego potrzebujesz, by utrzymywać równowagę w swoim życiu?\n" +
                "\n" +
                "Bardzo możliwe, że na wszystkie powyższe pytania odpowiedź jest pozytywna. Wówczas jednak tym bardziej interesuje Cię przegląd i naostrzenie narzędzi. Dla osób, którym w odpowiedzi na powyższe pytania wyświetliło się \"nie\", zaproponuję już wkrótce kilka pomysłów.\n" +
                "\n" +
                "Zatem: otwórz swój umysł na wiedzę praktyczną, tworzącą nowe nawyki.\n" +
                "\n" +
                "W kolejnym poście opiszę podstawową umiejętność, od której trzeba zacząć.\n" +
                "\n" +
                "Tymczasem...otwórz spadochron!",
            desc: "Wyobraź sobie, że idziesz przez las. W pewnym momencie widzisz człowieka, który usiłuje ściąć pokaźne drzewo.",
            src: "/images/blog5.jpg"
        }

    ]


    const [activeModal, setActiveModal] = useState(null);

    return <Layout>
        <CardGroup className="align-items-center">
            {Articles.map(({title, desc, content, src, id}) => <Col sm="3" className="m-1">
                <Card>
                    <CardImg className="card__image" src={src} alt="Card image cap"/>
                    <CardBody>
                        <CardTitle tag="h5">{title}</CardTitle>
                        <CardText>{desc}</CardText>
                        <Button onClick={() => setActiveModal(id)}>Pokaż więcej</Button>
                    </CardBody>
                </Card>
                {activeModal === id && <Modal isOpen>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalBody>
                        {content}
                    </ModalBody>
                    <CardImg top width="100%" src={src} alt="Card image cap"/>
                    <ModalFooter>
                        <Button onClick={() => setActiveModal(null)}>Wyjdź</Button>
                    </ModalFooter>
                </Modal>}
            </Col>)}
        </CardGroup>
    </Layout>
}

export default Blog;